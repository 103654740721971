import React, { useState, useEffect } from "react";

// Swipe library
import SwipeableViews from "react-swipeable-views";
import { bindKeyboard } from "react-swipeable-views-utils";

// Styles
import styled from "styled-components";

// services
import { getPosts } from "../services";

// components
import Post from "./post";

// Speech Synth
import Speech from "react-speech";

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

const AccessContainer = styled.div`
  .posts-container {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 3%;
    width: 90%;
  }
`;

export default function User() {
  const [postsData, setPosts] = useState(getPosts());
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState(null);

  const simulateClick = function (elem) {
    // Create our event (with options)
    var evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);
  };

  useEffect(() => {
    // // const PauseEl = document.querySelector("button.rs-pause");
    // // simulateClick(PauseEl);
    //   const ResumeBtn = document.querySelector("button.rs-resume");
    //   simulateClick(ResumeBtn);
    //   const ResumeBtn = document.querySelector("button.rs-resume");
    //   simulateClick(ResumeBtn);

    const buttonEl = document.querySelector("button.rs-play");
    setTimeout(() => {
      simulateClick(buttonEl);
    }, 1100);
  }, []);

  return (
    <AccessContainer>
      <Speech
        text={currentText || postsData[0].question}
        lang="en-US"
        pause={true}
        resume={true}
      />
      <BindKeyboardSwipeableViews
        axis="y"
        onChangeIndex={(i) => {
          console.log("index change", i);
          const PauseEl = document.querySelector("button.rs-pause");
          simulateClick(PauseEl);

          const ResumeBtn = document.querySelector("button.rs-resume");
          simulateClick(ResumeBtn);

          setTimeout(() => {
            const buttonEl = document.querySelector("button.rs-play");
            setCurrentText(postsData[i].question);
            simulateClick(buttonEl);
          }, 1000);
        }}
        className="posts-container"
        containerStyle={{ height: "100vh" }}
        enableMouseEvents={true}
        children={postsData.map((postData) => {
          return <Post key={postData.id} {...postData} />;
        })}
      ></BindKeyboardSwipeableViews>
    </AccessContainer>
  );
}
