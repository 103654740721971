import React, { useState } from "react";
import { styled as MuiStyled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// date formating
import { formatDistance } from "date-fns";

// styled componetns
import styled from "styled-components";

// post icons
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ShareIcon from "@mui/icons-material/Share";
// comment icons

const ExpandMore = MuiStyled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledPost = styled.div`
  height: 100vh;
  .post {
  }
`;

export default function Post(props) {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledPost>
      <Card className="post">
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: red[500] }}
              aria-label="recipe"
              src={props.postedBy.image}
            >
              R
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={props.postedBy.name}
          subheader={`${formatDistance(new Date(), 1234349999)} ago`}
        />

        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {props.question}
          </Typography>
        </CardContent>

        <CardActions disableSpacing>
          <div>
            <IconButton aria-label="like question">
              <ThumbUpAltIcon />
            </IconButton>
            <p style={{ display: "inline" }}>{props.likes}</p>
          </div>

          <div>
            <IconButton aria-label="dislike question">
              <ThumbDownAltIcon />
            </IconButton>
            <p style={{ display: "inline" }}>{props.dislikes}</p>
          </div>

          <div>
            <IconButton aria-label="comment" onClick={handleExpandClick}>
              <QuestionAnswerIcon />
            </IconButton>
            <p style={{ display: "inline" }}>{props.comments.length}</p>
          </div>

          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* <CommentsSection comments={props.comments} postedBy={props.postedBy} /> */}
          </CardContent>
        </Collapse>
      </Card>
    </StyledPost>
  );
}

// comment

{
}

// function CommentsSection({comments, postedBy}) {
//   return (
//     <Grid className="post" container wrap="nowrap" spacing={3}>
//       <Grid item>
//         <Avatar alt="Remy Sharp" src={imgLink} />
//       </Grid>
//       <Grid justifyContent="left" item xs zeroMinWidth>
//         <h3 style={{ margin: 0, marginTop: "6px", textAlign: "left" }}>
//           Michel Michel
//         </h3>

//         <p style={{ textAlign: "left" }}>Where do all the waters go?</p>
//         <p style={{ textAlign: "left", color: "gray" }}>posted 1 minute ago</p>
//       </Grid>
//     </Grid>
//   );
// }
