export const getPosts = () => {
  return [
    {
      postedBy: {
        name: "Erik Bahena",
        image:
          "https://www.nj.com/resizer/zovGSasCaR41h_yUGYHXbVTQW2A=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/SJGKVE5UNVESVCW7BBOHKQCZVE.jpg",
        link: "/erikbahena",
      },

      question: "What are your favorite guitar brands?",
      id: 0,
      likes: 10123,
      dislikes: 101,
      viewedBefore: false,
      postedOn: "Thu Nov 15 2021 21:13:45 GMT-0800 (Pacific Standard Time)",
      comments: [
        {
          commenter: {
            name: "Mike Smith",
            link: "/mikesmith/user/323jf",
            photo:
              "https://images.generated.photos/MFuCPs8HCCqyya-v79ZhVGRoTbWag0dxV1NzYxaCAr4/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LmNvbmQvMzZiYzY4/MjQtN2Y1Mi00MWRi/LTljMGEtYmM2YmVj/ZWY0ZTA4LmpwZw.jpg",
            status: "gold",
          },
          likes: 343,
          dislikes: 32,
          dateCommented:
            "Thu Nov 15 2021 21:13:45 GMT-0800 (Pacific Standard Time)",
          replies: [
            {
              commenter: {
                name: "Lazy Susan",
                link: "/lazysusam/user/323jf",
                photo:
                  "https://images.generated.photos/0qOkI8or_YoKkHHjk0Fz2PsRS5s8FKPEQXoPsM7t9OA/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LmNvbmQvNWQxMDA3/ZDAtMDllYy00MzIw/LWIxNTYtZGZiNGUz/YzIxNzg1LmpwZw.jpg",
                status: "none",
              },
              likes: 33,
              dislikes: 3,
              dateCommented:
                "Thu Nov 13 2021 21:13:45 GMT-0800 (Pacific Standard Time)",
            },
          ],
        },
      ],
    },
    {
      postedBy: {
        name: "Alex Lionel",
        image:
          "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZmFjZXxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
        link: "/erikbahena",
      },

      question:
        "Where are you from and What happened there that no one talks about.",
      id: 1,
      likes: 1013,
      dislikes: 10,
      viewedBefore: false,
      postedOn: "Thu Nov 15 2021 21:13:45 GMT-0800 (Pacific Standard Time)",
      comments: [
        {
          commenter: {
            name: "Mike Smith",
            link: "/mikesmith/user/323jf",
            photo:
              "https://images.generated.photos/MFuCPs8HCCqyya-v79ZhVGRoTbWag0dxV1NzYxaCAr4/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LmNvbmQvMzZiYzY4/MjQtN2Y1Mi00MWRi/LTljMGEtYmM2YmVj/ZWY0ZTA4LmpwZw.jpg",
            status: "gold",
          },
          likes: 343,
          dislikes: 32,
          dateCommented:
            "Thu Nov 15 2021 21:13:45 GMT-0800 (Pacific Standard Time)",
          replies: [
            {
              commenter: {
                name: "Lazy Susan",
                link: "/lazysusam/user/323jf",
                photo:
                  "https://images.generated.photos/0qOkI8or_YoKkHHjk0Fz2PsRS5s8FKPEQXoPsM7t9OA/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LmNvbmQvNWQxMDA3/ZDAtMDllYy00MzIw/LWIxNTYtZGZiNGUz/YzIxNzg1LmpwZw.jpg",
                status: "none",
              },
              likes: 33,
              dislikes: 3,
              dateCommented:
                "Thu Nov 13 2021 21:13:45 GMT-0800 (Pacific Standard Time)",
            },
          ],
        },
      ],
    },
    {
      postedBy: {
        name: "Cassy Rodgers",
        image:
          "https://images.unsplash.com/photo-1597223557154-721c1cecc4b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
        link: "/erikbahena",
      },

      question: "What are your funny bar stories?",
      id: 2,
      likes: 323,
      dislikes: 11,
      viewedBefore: false,
      postedOn: "Thu Nov 15 2021 21:13:45 GMT-0800 (Pacific Standard Time)",
      comments: [
        {
          commenter: {
            name: "Mike Smith",
            link: "/mikesmith/user/323jf",
            photo:
              "https://images.generated.photos/MFuCPs8HCCqyya-v79ZhVGRoTbWag0dxV1NzYxaCAr4/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LmNvbmQvMzZiYzY4/MjQtN2Y1Mi00MWRi/LTljMGEtYmM2YmVj/ZWY0ZTA4LmpwZw.jpg",
            status: "gold",
          },
          likes: 343,
          dislikes: 32,
          dateCommented:
            "Thu Nov 15 2021 21:13:45 GMT-0800 (Pacific Standard Time)",
          replies: [
            {
              commenter: {
                name: "Lazy Susan",
                link: "/lazysusam/user/323jf",
                photo:
                  "https://images.generated.photos/0qOkI8or_YoKkHHjk0Fz2PsRS5s8FKPEQXoPsM7t9OA/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LmNvbmQvNWQxMDA3/ZDAtMDllYy00MzIw/LWIxNTYtZGZiNGUz/YzIxNzg1LmpwZw.jpg",
                status: "none",
              },
              likes: 33,
              dislikes: 3,
              dateCommented:
                "Thu Nov 13 2021 21:13:45 GMT-0800 (Pacific Standard Time)",
            },
          ],
        },
      ],
    },
  ];
};
