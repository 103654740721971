import React, { useState } from "react";

import NavBar from "./components/NavBar";

import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute.js";

import Login from "./components/Login";
import SignUp from "./components/SignUp";
import User from "./components/User.js";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  return (
    <React.Fragment>
      <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />

      <Routes>
        <Route
          exact
          path="/login"
          element={<Login setIsLoggedIn={setIsLoggedIn} />}
        />

        <Route exact path="/signup" element={<SignUp />} />

        <Route path="/user" element={<PrivateRoute />}>
          <Route path="/user" element={<User />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
}

export default App;
